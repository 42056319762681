import React, { FC, ReactNode, useState } from "react";
import { TableCell, TableRow, Collapse, Grid, IconButton, styled, Tooltip } from "@mui/material";
import If from "components/__helpers__/if";
import { THeadCell } from "utils/types";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IRow } from "components/tableComponent";
import colors from "styles/colors";

export const EmptyCell = styled(TableCell)(() => ({
    "@media print": {
        padding: "0 !important",
    },
    padding: "0 0 0 20px !important",
    width: 0,
    borderColor: "white !important",
}));

const CollapsibleContentRow = styled(TableRow)(() => ({
    backgroundColor: colors.secondTable,
    "& p": {
        whiteSpace: "pre-line",
    },
}));

interface IRowProps {
    row: IRow;
    index: number;
    headCells: THeadCell[];
    collapsible?: boolean;
    hasPrependIcons?: boolean;
}

const Row: FC<IRowProps> = ({ row, index, headCells, collapsible = false, hasPrependIcons = false }) => {
    const [open, setOpen] = useState(false);

    const onClick = row.onClick || (collapsible ? () => setOpen(!open) : undefined);

    return (
        <>
            <Tooltip key={index} title={row.tooltipText ? row.tooltipText : ""} placement="top">
                <TableRow key={`row-${index}`} hover={!!onClick} sx={row.disabled ? { opacity: "0.5" } : {}}>
                    <EmptyCell sx={open ? { borderColor: `${colors.border} !important` } : {}} onClick={onClick} />
                    <If truthy={collapsible || hasPrependIcons}>
                        <TableCell onClick={() => onClick?.()}>
                            <Grid container sx={{ gap: "3px" }}>
                                {collapsible && !row.disabled && row.collapsibleContent ? (
                                    <IconButton
                                        sx={{ padding: 0 }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                ) : null}
                                {hasPrependIcons &&
                                    row.prependIcons?.map((icon): ReactNode => {
                                        return icon;
                                    })}
                            </Grid>
                        </TableCell>
                    </If>
                    {row.data.map((r, index2: number) => (
                        <TableCell
                            key={index2}
                            align={headCells[index2].alignCell}
                            padding={headCells[index2].padding}
                            sx={{
                                color: row.rowColor ? row.rowColor : colors.black,
                                verticalAlign: headCells[index2].alignCellVertical || "baseline",
                                whiteSpace: headCells[index2].allowLineBreaks ? "pre-line !important" : "nowrap",
                            }}
                            onClick={() => headCells[index2].label !== "" && onClick?.()}
                        >
                            {r}
                        </TableCell>
                    ))}
                    <EmptyCell
                        sx={open ? { borderColor: `${colors.border} !important` } : {}}
                        onClick={() => onClick?.()}
                    />
                </TableRow>
            </Tooltip>

            {row.subRows?.map((subRow, index) => {
                const firstAlignedCellIndex = subRow.alignedData.findIndex((d) => d !== null);
                const relevantAlignedData = subRow.alignedData.slice(firstAlignedCellIndex);

                return (
                    <TableRow key={`subRow_${index}`} hover={subRow.hover}>
                        <EmptyCell onClick={subRow.onClick} />
                        <TableCell
                            colSpan={firstAlignedCellIndex}
                            sx={{ paddingLeft: "50px" }}
                            onClick={subRow.onClick}
                        >
                            {subRow.freeform}
                        </TableCell>
                        {relevantAlignedData.map((data, j) => (
                            <TableCell
                                key={`subrow_cell_${index}_${j}`}
                                onClick={() => headCells[firstAlignedCellIndex + j].label !== "" && subRow.onClick?.()}
                            >
                                {data}
                            </TableCell>
                        ))}
                        <EmptyCell onClick={subRow.onClick} />
                    </TableRow>
                );
            })}
            <If truthy={collapsible && row.collapsibleContent}>
                <CollapsibleContentRow>
                    <TableCell colSpan={row.data.length + 3} sx={{ padding: 0, border: "none" }}>
                        <Collapse in={open} unmountOnExit timeout="auto">
                            <div style={{ padding: 20 }}>{row.collapsibleContent}</div>
                        </Collapse>
                    </TableCell>
                </CollapsibleContentRow>
            </If>
        </>
    );
};

export default Row;
