import React, { FC } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { IProduct } from "reducers/products";
import { productsHeadCells } from "views/arena-staff/productsView";
import { LocalizeText } from "components/localizer";
import PrettifyPrice from "components/prettifyPrice";
import { IUsePaginationStore } from "utils/usePaginationStore";

interface IProductsTableProps {
    pagination: IUsePaginationStore<"products">;
    onClick?: (product: IProduct) => void;
}

const ProductsTable: FC<IProductsTableProps> = ({ pagination, onClick }) => {
    const mapRows = (products: IProduct[]): IRow[] =>
        products.map((product) => {
            const { product_key, name, service_section_name, community_title } = product;

            const priceAmount = parseFloat(product.price?.amount_excl_vat ?? "0");
            const price = priceAmount > 0 ? <PrettifyPrice amount={priceAmount} /> : <LocalizeText tag="free2" />;

            return {
                onClick: onClick ? () => onClick(product) : undefined,
                data: [
                    product_key || "-",
                    name || "-",
                    service_section_name || "-",
                    community_title ?? "-",
                    product.price ? price : "-",
                    product.price?.vat_proportion ? `${parseFloat(product.price.vat_proportion)}%` : "-",
                ],
            };
        });

    const rows = mapRows(pagination.results);

    return (
        <TableComponent
            headCells={productsHeadCells()}
            isLoading={pagination.query.isFetching}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
        />
    );
};

export default ProductsTable;
