import React, { ChangeEvent, FC, ReactElement, useCallback, useMemo, useState } from "react";
import { parseISO, format, isBefore, addDays } from "date-fns";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import { dateFormats } from "utils/formats";
import { getSubscriptionPeriod } from "utils/ecommerseTypes";
import TableComponent, { IRow } from "components/tableComponent";
import { Box, FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import StyledSwitchContainer from "./switchContainer";
import { IPaginationStore } from "utils/paginationStore";
import { ISubscription } from "reducers/subscriptions";
import { truncateText } from "utils/helpers";
import colors from "styles/colors";
import { useNavigate } from "react-router-dom";
import Dropdown, { TDropdownItem } from "components/dropdown";

interface IProps {
    pagination: IPaginationStore<ISubscription>;
    loading: boolean;
}

export const StaffSubscriptionsTable: FC<IProps> = ({ pagination, loading }) => {
    const [activeFilter, setActiveFilter] = useState<boolean>(false);
    const navigate = useNavigate();

    const getDiscountText = (subscription: ISubscription): ReactElement => {
        const discountText: string = subscription.discounts_max_amount_excl_vat
            .map((num) => `${num} kr (${Math.round((num / subscription.base_cost_excl_vat) * 100)} %)`)
            .join(", ");

        return (
            <Tooltip placement="right" arrow title={discountText}>
                <span key={`${subscription.id}`}>{truncateText(discountText, 20)}</span>
            </Tooltip>
        );
    };

    const getInternalNoteText = (subscription: ISubscription): ReactElement => {
        const internalNoteText: string = subscription.discount_internal_note.join(", ");

        return <span key={`${subscription.id}`}>{truncateText(internalNoteText, 20)}</span>;
    };

    const getTrialPeriodColor = (subscription: ISubscription): string | undefined => {
        if (subscription.is_ending_next_next_month || subscription.final_payment_made) {
            return undefined;
        }

        if (subscription.trial_period_until && !subscription.not_invoiced) {
            return colors.greyText;
        }
        if (isBefore(addDays(parseISO(subscription.trial_period_until ?? ""), -30), new Date())) {
            return colors.vkBlue;
        }
        return undefined;
    };

    const viewAsRepresentative = useCallback(
        (subscription: ISubscription): void => {
            window.localStorage.setItem("landingPage", "default");
            window.localStorage.setItem("currentOrg", JSON.stringify(subscription.organization_collection));

            navigate(`/dashboard?org=${subscription.organization_collection.org_nr}`, { replace: true });
            navigate(`/subscriptions/${subscription.membership_id}/${subscription.uuid}/details`);
            window.location.reload();
        },
        [navigate]
    );

    const getDropdownItems = useCallback(
        (subscription: ISubscription): TDropdownItem[] => {
            const items = [
                {
                    onClick: () => viewAsRepresentative(subscription),
                    label: <LocalizeText tag="showSubscription" />,
                },
            ];

            return items;
        },
        [viewAsRepresentative]
    );

    const subscriptionsHeadCells = useCallback((): THeadCell[] => {
        return [
            {
                id: "identifier",
                sortId: "identifier",
                label: <LocalizeText tag="id" />,
            },
            {
                id: "productAndCommunity",
                sortId: "product__name",
                label: <LocalizeText tag="productAndCommunity" />,
            },
            {
                id: "company",
                sortId: "membership__organization__name",
                label: <LocalizeText tag="company" />,
            },
            {
                id: "binding_period",
                sortId: "end_date",
                label: <LocalizeText tag="subscriptionPeriod" />,
            },
            {
                id: "trialPeriod",
                sortId: "trial_period_until",
                label: <LocalizeText tag="trialPeriodShort" />,
            },
            {
                id: "discount",
                sortId: "discounts__amount_excl_vat",
                label: <LocalizeText tag="discount" />,
            },
            {
                id: "internalNote",
                sortId: "discounts__internal_note",
                label: <LocalizeText tag="internalNote" />,
            },
            { id: "", label: "", alignCell: "right" },
        ];
    }, []);

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((sub) => ({
            disabled: sub.final_payment_made,
            rowColor: sub.is_ending_next_next_month ? colors.vkBlue : undefined,
            data: [
                sub.identifier,
                sub.product_name,
                sub.organization_name,
                getSubscriptionPeriod(sub),

                <Typography variant="inherit" key="trialPeriod" color={getTrialPeriodColor(sub)}>
                    {sub.trial_period_until ? format(parseISO(sub.trial_period_until ?? ""), dateFormats.WEBDATE) : ""}
                </Typography>,
                <Typography
                    variant="inherit"
                    key="discount"
                    color={sub.has_discount_ending_next_next_month ? colors.vkBlue : undefined}
                >
                    {getDiscountText(sub)}
                </Typography>,
                getInternalNoteText(sub),
                <Dropdown inRow key={sub.id} items={getDropdownItems(sub)} />,
            ],
        }));
    }, [getDropdownItems, pagination.results]);

    const onIncludeInactiveChange = (event: ChangeEvent<HTMLInputElement>): void => {
        pagination.setParam("active", !event.target.checked ? true : null);
        setActiveFilter(event.target.checked);
    };

    return (
        <Box>
            <StyledSwitchContainer>
                <FormControlLabel
                    control={<Switch checked={activeFilter} onChange={onIncludeInactiveChange} color="primary" />}
                    label={<LocalizeText tag="includeInactiveSubscriptions" />}
                />
            </StyledSwitchContainer>
            <TableComponent
                headCells={subscriptionsHeadCells()}
                isLoading={loading}
                skeletonVariant="options"
                pagination={pagination}
                rows={rows}
            />
        </Box>
    );
};
