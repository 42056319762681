import React from "react";
import { Routes, HashRouter, Route } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Notification from "components/notifications";
import defaultTheme from "styles/mainTheme";
import customGlobalStyles from "components/globalStyles";

// Contexts
import { AuthProvider } from "contexts/auth";
import { NotificationProvider } from "contexts/notification";
import { LanguageProvider } from "contexts/language";
import { SubscriptionsProvider } from "contexts/subscriptions";
import { ArenasProvider } from "contexts/arenas";
import { ProfileProvider } from "contexts/profile";
import { MembershipsProvider } from "contexts/memberships";
import { RealestatesProvider } from "contexts/realestates";
import { NewsArticleProvider } from "contexts/newsArticles";
import { FeatureFlagProvider } from "contexts/featureFlag";
import { RoleManagementProvider } from "contexts/roleManagement";
import { ModalProvider } from "contexts/modals";
import { LeasesProvider } from "contexts/leases";
import { CommunityProvider } from "contexts/community";
import { ExternalMembershipsProvider } from "contexts/externalMemberships";
import { InvoicesProvider } from "contexts/invoices";
import { ProductsProvider } from "contexts/products";
import { MembersProvider } from "contexts/members";

// Views
import LoginView from "views/login";
import SetPasswordView from "views/setPassword";
import ArenaView from "views/membership/arenaView";
import MembershipsView from "views/membership/membershipsView";
import SubscriptionsView from "views/membership/subscriptionsView";
import ContactUsView from "views/contactUsView";
import BuildingsView from "views/lease/buildingsView";
import LeasesViewV2 from "views/lease/leaseViewV2";
import CustomerLedgers from "views/lease/customerLedgers";
import ProfileView from "views/profileView";
import StartpageView from "views/startpageView";
import NewsView from "views/newsView";
import TenantDashboardView from "views/lease/tenantDashboardView";
import LeaseSelectView from "views/lease/leaseSelectView";
import ArenaMemberDashboard from "views/membership/arenaMemberDashboardView";
import PageNotFound from "views/pageNotFound";
import BuildingInformation from "views/lease/buildingInformation";
import ArenaBuildings from "views/membership/arenaBuildings";
import ArenaInformation from "views/membership/arenaInformation";
import ResetPassword from "views/resetPassword";
import Interceptor from "components/__helpers__/interceptor";
import AcceptInvitationView from "views/acceptInvitation";
import TasksView from "views/lease/tasksView";
import ServicesView from "views/servicesView";
import RoleUserManagementView from "views/roleUserManagement/roleUserManagementView";
import LeaseUserDetailsView from "views/roleUserManagement/agreementDetailViews/leaseUserDetailsView";
import MembershipUserDetailsView from "views/roleUserManagement/agreementDetailViews/membershipUserDetailsView";
import UnsubscribeMailListView from "views/unsubscribeMailList";
import ArenaCommunityStaffDashboardView from "views/arena-staff/arenaCommunityStaffDashboardView";
import { MembershipsStaffView } from "views/arena-staff/membershipStaffView";
import CommunityStaffManagementView from "views/arena-staff/communityStaffManagementView";
import CostCalculationRunsView from "views/arena-staff/costCalcultaionRunView";
import StaffRepresentativesView from "views/arena-staff/staffRepresentativesView";
import ExternalMembershipsView from "views/arena-staff/externalMembershipsView";
import InvoicingView from "views/arena-staff/invoicingView";
import InvoiceEventOrdersView from "views/arena-staff/invoiceEventOrdersView";
import InvoiceView from "views/arena-staff/invoiceView";
import ProductsView from "views/arena-staff/productsView";
import InvoiceFileUploadView from "views/arena-staff/invoiceFileUploadView";
import SergelConferenceView from "views/arena-staff/sergelConferenceView";
import StaffSubscriptionsView from "views/arena-staff/staffSubscriptionsView";
import { MembersView } from "views/membership/membersView";
import { SubscriptionView } from "views/membership/subscriptionView";
import { StatisticsView } from "views/arena-staff/statisticsView";
import { MembershipView } from "views/membership/membershipView";
import EventsView from "components/events/eventsView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PrivateRoute from "./components/privateRoute";
import StaffMembersView from "views/arena-staff/staffMembersView";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const providerWrapping = (component: React.ReactNode): JSX.Element => {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthProvider>
                <ProfileProvider>
                    <Interceptor>
                        <RoleManagementProvider>
                            <CommunityProvider>
                                <MembershipsProvider>
                                    <ExternalMembershipsProvider>
                                        <SubscriptionsProvider>
                                            <RealestatesProvider>
                                                <LeasesProvider>
                                                    <InvoicesProvider>
                                                        <ProductsProvider>
                                                            <MembersProvider>
                                                                <ModalProvider>{component}</ModalProvider>
                                                            </MembersProvider>
                                                        </ProductsProvider>
                                                    </InvoicesProvider>
                                                </LeasesProvider>
                                            </RealestatesProvider>
                                        </SubscriptionsProvider>
                                    </ExternalMembershipsProvider>
                                </MembershipsProvider>
                            </CommunityProvider>
                        </RoleManagementProvider>
                    </Interceptor>
                </ProfileProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
};
const SiteRoutes: React.FC = () => (
    <StyledEngineProvider injectFirst>
        {customGlobalStyles}
        <ThemeProvider theme={defaultTheme}>
            <LanguageProvider>
                <NotificationProvider>
                    <FeatureFlagProvider>
                        <Notification />
                        <HashRouter>{providerWrapping(<AuthSwitch />)}</HashRouter>
                    </FeatureFlagProvider>
                </NotificationProvider>
            </LanguageProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

const AuthSwitch: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<LoginView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="/setPassword/:token" element={<SetPasswordView />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/unsubscribe/:encoded_email/:token" element={<UnsubscribeMailListView />} />
            <Route
                path="/acceptInvitation/:invitationUuid"
                element={
                    <LeasesProvider>
                        <AcceptInvitationView />
                    </LeasesProvider>
                }
            />

            <Route
                path="/dashboard"
                element={
                    <PrivateRoute
                        component={
                            <NewsArticleProvider>
                                <StartpageView />
                            </NewsArticleProvider>
                        }
                    />
                }
            />
            <Route path="/profile" element={<PrivateRoute component={<ProfileView />} />} />
            <Route path="/contact-us" element={<PrivateRoute component={<ContactUsView />} />} />
            <Route
                path="/news"
                element={
                    <PrivateRoute
                        component={
                            <NewsArticleProvider>
                                <NewsView />
                            </NewsArticleProvider>
                        }
                    />
                }
            />
            <Route
                path="/tenant-dashboard"
                element={
                    <PrivateRoute
                        component={
                            <NewsArticleProvider>
                                <TenantDashboardView />
                            </NewsArticleProvider>
                        }
                    />
                }
            />
            <Route path="/tasks" element={<PrivateRoute component={<TasksView />} />} />
            <Route path="/services" element={<PrivateRoute component={<ServicesView />} />} />
            <Route path="/realestates" element={<PrivateRoute component={<BuildingsView />} />} />
            <Route path="/realestates/:buildingUuid" element={<PrivateRoute component={<BuildingInformation />} />} />
            <Route path="/leases" element={<PrivateRoute component={<LeasesViewV2 />} />} />
            <Route path="/customer-ledgers" element={<PrivateRoute component={<LeaseSelectView />} />} />
            <Route path="/customer-ledgers/:lease" element={<PrivateRoute component={<CustomerLedgers />} />} />
            <Route
                path="/arena-member-dashboard"
                element={
                    <PrivateRoute
                        component={
                            <NewsArticleProvider>
                                <ArenaMemberDashboard />
                            </NewsArticleProvider>
                        }
                    />
                }
            />
            <Route path="/statistics" element={<PrivateRoute component={<StatisticsView />} />} />
            <Route path="/memberships" element={<PrivateRoute component={<MembershipsView />} />} />
            <Route path="/subscriptions" element={<PrivateRoute component={<MembershipsView />} />} />
            <Route
                path="/subscriptions/:membershipId/:subscriptionUuid/:tab"
                element={<PrivateRoute component={<SubscriptionView />} />}
            />
            <Route path="/subscriptions/:membershipId" element={<PrivateRoute component={<SubscriptionsView />} />} />
            <Route path="/members" element={<PrivateRoute component={<MembersView />} />} />
            <Route path="/arena-offices" element={<PrivateRoute component={<MembershipsView />} />} />
            <Route
                path="/arena-offices/:membershipId"
                element={
                    <PrivateRoute
                        component={
                            <ArenasProvider>
                                <ArenaView />
                            </ArenasProvider>
                        }
                    />
                }
            />
            <Route
                path="/arena-buildings"
                element={
                    <PrivateRoute
                        component={
                            <ArenasProvider>
                                <ArenaBuildings />
                            </ArenasProvider>
                        }
                    />
                }
            />
            <Route
                path="/arena-buildings/:arenaBuildingUuid"
                element={
                    <PrivateRoute
                        component={
                            <ArenasProvider>
                                <ArenaInformation />
                            </ArenasProvider>
                        }
                    />
                }
            />
            <Route path="/representatives" element={<PrivateRoute component={<RoleUserManagementView />} />} />
            <Route
                path="/representatives/membership/:membershipId"
                element={<PrivateRoute component={<MembershipUserDetailsView />} />}
            />
            <Route
                path="/representatives/lease/:leaseUuid"
                element={<PrivateRoute component={<LeaseUserDetailsView />} />}
            />
            <Route
                path="/staff-dashboard"
                element={
                    <PrivateRoute
                        component={
                            <NewsArticleProvider>
                                <ArenaCommunityStaffDashboardView />
                            </NewsArticleProvider>
                        }
                    />
                }
            />
            <Route path="/staff-memberships" element={<PrivateRoute component={<MembershipsStaffView />} />} />
            <Route
                path="/staff-memberships/:membershipId/:tab"
                element={<PrivateRoute component={<MembershipView />} />}
            />
            <Route
                path="/staff-memberships/:membershipId/:tab/:period"
                element={<PrivateRoute component={<MembershipView />} />}
            />
            <Route
                path="/staff-external-memberships"
                element={<PrivateRoute component={<ExternalMembershipsView />} />}
            />
            <Route path="/staff-sergel" element={<PrivateRoute component={<SergelConferenceView />} />} />
            <Route path="/staff-subscription" element={<PrivateRoute component={<StaffSubscriptionsView />} />} />
            <Route path="/staff-members" element={<PrivateRoute component={<StaffMembersView />} />} />
            <Route path="/staff-products" element={<PrivateRoute component={<ProductsView />} />} />
            <Route
                path="/staff-invoicing/app-purchases"
                element={<PrivateRoute component={<InvoiceFileUploadView />} />}
            />
            <Route path="/staff-invoicing" element={<PrivateRoute component={<InvoicingView />} />} />
            <Route path="/staff-invoicing/:tab" element={<PrivateRoute component={<InvoicingView />} />} />
            <Route
                path="/staff-invoicing/memberships/:period"
                element={<PrivateRoute component={<InvoicingView />} />}
            />
            <Route
                path="/staff-invoicing/events/:eventUuid"
                element={<PrivateRoute component={<InvoiceEventOrdersView />} />}
            />
            <Route
                path="/staff-invoices/:orderUuid/:invoiceUuid"
                element={<PrivateRoute component={<InvoiceView />} />}
            />
            <Route path="/staff-invoices/:orderUuid" element={<PrivateRoute component={<InvoiceView />} />} />
            <Route path="/staff-representatives" element={<PrivateRoute component={<StaffRepresentativesView />} />} />
            <Route path="/staff-management" element={<PrivateRoute component={<CommunityStaffManagementView />} />} />
            <Route path="/staff-cost-calculations" element={<PrivateRoute component={<CostCalculationRunsView />} />} />
            <Route path="/events" element={<PrivateRoute component={<EventsView />} />} />
            <Route path="/*" element={<PrivateRoute component={<PageNotFound />} />} />
            <Route path="/*" element={<PageNotFound />} />
        </Routes>
    );
};

export default SiteRoutes;
