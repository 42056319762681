import { TMembershipId } from "./memberships";
import { IOrganizationCollection } from "./profile";

export interface ISubscription {
    id: number;
    identifier: string;
    uuid: string;
    membership_id: string;
    start_date: string;
    end_date: string;
    canceled_date: string;
    community_city: string;
    community_title: string;
    trial_period_until: string;
    binding_period_until: string;
    notice_period_until: string;
    earliest_cancellation_date: string;
    vat_proportion: number;
    base_cost_excl_vat: number;
    total_cost_excl_vat: number;
    total_cost_incl_vat: number;
    active_associations: number;
    max_active_associations: number;
    next_invoice_date: string;
    final_payment_made: boolean;
    personal_user: string;
    can_add_associations: boolean;
    earliest_member_activation_date: string;
    not_invoiced: boolean;
    latest_member_deactivation_date: string;
    product_name: string;
    total_associations: number;
    organization_name: string;
    discounts_max_amount_excl_vat: number[];
    discount_internal_note: string[];
    has_discount_ending_next_next_month: boolean;
    is_ending_next_next_month: boolean;
    organization_collection: IOrganizationCollection;
}

export interface IStockrecord {
    id: number;
    base_price_incl_tax: number;
    base_price_excl_tax: number;
    currency: string;
    discount: string;
    total_price_excl_tax: number;
    total_price_incl_tax: number;
    tax_level: string;
    total_price_tax_amount: string;
}

export interface INationalAccess {
    id: number;
    name: string;
    description: string;
    allow_stacking: boolean;
    has_stockrecords: boolean;
    stockrecords?: IStockrecord[];
    attribute_options: string[];
}

export interface ISubscriptionsQueryParams {
    membership_id?: string;
    active?: boolean;
    member_email?: string;
    search?: string;
    ordering?: string;
    community_id?: string;
    search_by?: string;
}

export interface ISubscriptions {
    nationalAccess?: INationalAccess[];
    isFetchingSubscription: boolean;
    isUpdatingSubscription: boolean;
    isCancelingSubscription: boolean;
    isRemovingNationalAccess: boolean;
    isFetchingNationalAccess: boolean;
    isSettingNationalAccess: boolean;
    isSettingUpMoreBarium: boolean;
    fetchSubscription: (uuid: string) => Promise<ISubscription>;
    updateSubscription: (uuid: string, data: Record<string, unknown>) => Promise<ISubscription>;
    getNationalAccess: (productId: number) => void;
    setNationalAccess: (membershipId: TMembershipId, subscriptionId: number, startDate: Date) => void;
    cancelSubscription: (subscription_uuid: string, expires: Date | null) => Promise<boolean>;
    removeNationalAccess: (subscriptionId: number, expires: Date) => void;
    getBariumRequestMoreMembershipURL: (membershipId: number) => Promise<boolean>;
}

export const initialState: ISubscriptions = {
    isFetchingSubscription: false,
    isUpdatingSubscription: false,
    isCancelingSubscription: false,
    isRemovingNationalAccess: false,
    isSettingNationalAccess: false,
    isFetchingNationalAccess: false,
    isSettingUpMoreBarium: false,
    fetchSubscription: async () => ({}) as ISubscription,
    updateSubscription: async () => ({}) as ISubscription,
    setNationalAccess: async () => false,
    getNationalAccess: async () => false,
    cancelSubscription: async () => false,
    removeNationalAccess: async () => false,
    getBariumRequestMoreMembershipURL: async () => false,
};

export type IAction =
    | { type: "FETCH_SUBSCRIPTION" }
    | { type: "FETCH_SUBSCRIPTION_SUCCESS" }
    | { type: "FETCH_SUBSCRIPTION_FAILURE" }
    | { type: "UPDATE_SUBSCRIPTION" }
    | { type: "UPDATE_SUBSCRIPTION_SUCCESS" }
    | { type: "UPDATE_SUBSCRIPTION_FAILURE" }
    | { type: "SET_UP_BARIUM" }
    | { type: "SET_UP_BARIUM_SUCCESS" }
    | { type: "SET_UP_BARIUM_FAILURE" }
    | { type: "CANCEL_SUBSCRIPTION" }
    | { type: "CANCEL_SUBSCRIPTION_SUCCESS" }
    | { type: "CANCEL_SUBSCRIPTION_FAILURE" }
    | { type: "REMOVE_NATIONAL_ACCESS" }
    | { type: "REMOVE_NATIONAL_ACCESS_SUCCESS" }
    | { type: "REMOVE_NATIONAL_ACCESS_FAILURE" }
    | { type: "SET_NATIONAL_ACCESS" }
    | { type: "SET_NATIONAL_ACCESS_SUCCESS" }
    | { type: "SET_NATIONAL_ACCESS_FAILURE" }
    | { type: "GET_NATIONAL_ACCESS" }
    | { type: "GET_NATIONAL_ACCESS_SUCCESS"; nationalAccess: INationalAccess[] }
    | { type: "GET_NATIONAL_ACCESS_FAILURE" };

function reducer(state: ISubscriptions, action: IAction): ISubscriptions {
    switch (action.type) {
        case "FETCH_SUBSCRIPTION":
            return { ...state, isFetchingSubscription: true };
        case "FETCH_SUBSCRIPTION_SUCCESS":
        case "FETCH_SUBSCRIPTION_FAILURE":
            return { ...state, isFetchingSubscription: false };

        case "UPDATE_SUBSCRIPTION":
            return { ...state, isUpdatingSubscription: true };
        case "UPDATE_SUBSCRIPTION_SUCCESS":
        case "UPDATE_SUBSCRIPTION_FAILURE":
            return { ...state, isUpdatingSubscription: false };

        case "SET_UP_BARIUM":
            return { ...state, isSettingUpMoreBarium: true };
        case "SET_UP_BARIUM_SUCCESS":
        case "SET_UP_BARIUM_FAILURE":
            return { ...state, isSettingUpMoreBarium: false };

        case "CANCEL_SUBSCRIPTION":
            return { ...state, isCancelingSubscription: true };
        case "CANCEL_SUBSCRIPTION_SUCCESS":
        case "CANCEL_SUBSCRIPTION_FAILURE":
            return { ...state, isCancelingSubscription: false };

        case "REMOVE_NATIONAL_ACCESS":
            return { ...state, isRemovingNationalAccess: true };
        case "REMOVE_NATIONAL_ACCESS_SUCCESS":
        case "REMOVE_NATIONAL_ACCESS_FAILURE":
            return { ...state, isRemovingNationalAccess: false };

        case "SET_NATIONAL_ACCESS":
            return { ...state, isSettingNationalAccess: true };
        case "SET_NATIONAL_ACCESS_SUCCESS":
        case "SET_NATIONAL_ACCESS_FAILURE":
            return { ...state, isSettingNationalAccess: false };

        case "GET_NATIONAL_ACCESS":
            return { ...state, isFetchingNationalAccess: true };
        case "GET_NATIONAL_ACCESS_SUCCESS":
            return {
                ...state,
                isFetchingNationalAccess: false,
                nationalAccess: action.nationalAccess,
            };
        case "GET_NATIONAL_ACCESS_FAILURE":
            return { ...state, isFetchingNationalAccess: false };

        default:
            return state;
    }
}

export default reducer;
