import React, { FC, useContext, useEffect } from "react";
import Drawer from "components/drawer";
import { IArena } from "reducers/arenas";
import { LocalizeText } from "components/localizer";
import { TDrawer } from "components/tableComponents/arenaTable";
import ArenaInformation from "components/drawers/arena/arenaInformation";
import { MembershipsContext } from "contexts/memberships";
import { Box } from "@mui/material";
import ArenasContext from "contexts/arenas";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { ModalContext } from "contexts/modals";

interface IProps {
    drawerType: TDrawer;
    pagination: IUsePaginationStore<"arenas">;
    arena: IArena;
    onDrawerClose: () => void;
    membershipId: number;
}

const ArenaDrawerGroup: FC<IProps> = ({ drawerType, onDrawerClose, arena, pagination, membershipId }) => {
    const { membership, isFetchingMemberships, fetchMembership } = useContext(MembershipsContext);
    const { cancelArena, updateArena } = useContext(ArenasContext);
    const { closeDrawers } = useContext(ModalContext);

    useEffect(() => {
        if (!membership && !isFetchingMemberships) {
            fetchMembership(membershipId);
        }
        // eslint-disable-next-line
    }, []);

    const reloadArenas = async (): Promise<void> => {
        await pagination.query.refetch();
    };

    const onAcceptRemoveArena = async (newDate: string): Promise<void> => {
        await cancelArena(arena.uuid, newDate);
        closeDrawers();
        reloadArenas();
    };
    const onSubmitUpdate = async (newData: Partial<IArena>): Promise<void> => {
        await updateArena(arena.uuid, newData);
        closeDrawers();
        reloadArenas();
    };

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
            data-testid="arenaDrawerGroup-root"
        >
            <Drawer
                isOpen={drawerType === "SHOW_INFORMATION"}
                onClose={onDrawerClose}
                title={<LocalizeText tag="showArenaOffice" />}
                noData={!arena || !membership}
            >
                {arena && membership && (
                    <ArenaInformation
                        arena={arena}
                        onSubmitUpdate={onSubmitUpdate}
                        onAcceptRemoveArena={onAcceptRemoveArena}
                    />
                )}
            </Drawer>
        </Box>
    );
};

export default ArenaDrawerGroup;
