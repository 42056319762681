import React, { FC, useCallback, useMemo, useState } from "react";
import { format, isAfter, isValid, parseISO, sub } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem, Box } from "@mui/material";
import { DatePicker } from "components/datePicker";
import { usePermission } from "utils/hooks/usePermission";
import { TLanguageTag } from "contexts/language";

interface IProps {
    onSubmit: (date: string) => void;
    dateEnd: string;
    earliestCancellationDate: string;
}

export const CancelArenaDatePicker: FC<IProps> = ({ onSubmit, dateEnd, earliestCancellationDate }) => {
    const { hasPermission } = usePermission();

    const { earliest, officialEarliest } = useMemo(() => {
        const officialEarliest = parseISO(earliestCancellationDate ?? "");
        const earliest = hasPermission("can_override_earliest_officesubscription") ? new Date() : officialEarliest;
        return { earliest, officialEarliest };
    }, [hasPermission, earliestCancellationDate]);

    const [endDate, setEndDate] = useState<Date | null>(dateEnd ? parseISO(dateEnd) : officialEarliest);

    const [validation, setValidation] = useState({
        isValid: true,
        reasonTag: "",
    });

    const validateDataCallback = useCallback(
        async (date: Date) => {
            if (!date) {
                return setValidation({ isValid: true, reasonTag: "" });
            }
            if (!isAfter(date, sub(earliest, { days: 1 }))) {
                return setValidation({ isValid: false, reasonTag: "errorMinDate" });
            }
            if (!isValid(date)) {
                return setValidation({ isValid: false, reasonTag: "errorDate" });
            }
            return setValidation({ isValid: true, reasonTag: "" });
        },
        [earliest]
    );

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        onSubmit(endDate ? format(endDate, dateFormats.WEBDATE) : "");
    };

    const onChange = (date: unknown): void => {
        validateDataCallback(date as Date);
        setEndDate(date as Date);
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText primary={<LocalizeText tag="selectEndDateArena" />} />
                    </ListItem>
                    <ListItem>
                        <DatePicker
                            minDate={earliest}
                            format={dateFormats.WEBDATE}
                            label={<LocalizeText tag="endDate" />}
                            value={endDate}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !validation.isValid,
                                    helperText: validation.reasonTag && (
                                        <LocalizeText tag={validation.reasonTag as TLanguageTag} />
                                    ),
                                },
                            }}
                            onChange={onChange}
                        />
                    </ListItem>
                    <ListItem>
                        <VKButton type="submit" tag="save" disabled={!validation.isValid} template="primary" />
                    </ListItem>
                </List>
            </form>
        </Box>
    );
};
