import React, { FC, useContext, useEffect } from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import SearchField from "components/tableComponents/paginationSearchField";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import ProductsTable from "components/tableComponents/productsTable";
import CommunityContext from "contexts/community";
import CreateProduct from "components/drawers/createProduct";
import ProductInformation from "components/drawers/productInformation";
import { usePermission } from "utils/hooks/usePermission";
import { usePaginationStore } from "utils/usePaginationStore";

export const productsHeadCells = (): THeadCell[] => {
    return [
        {
            id: "product_key",
            sortId: "product_key",
            label: <LocalizeText tag="productKey" />,
        },
        {
            id: "name",
            sortId: "name",
            label: <LocalizeText tag="product" />,
        },
        {
            id: "service_section",
            sortId: "service_section",
            label: <LocalizeText tag="serviceSection" />,
        },
        {
            id: "arena",
            sortId: "community__title",
            label: <LocalizeText tag="community" />,
        },
        {
            id: "amount_excl_vat",
            sortId: "price__amount_excl_vat",
            label: <LocalizeText tag="priceExclVat" />,
        },
        { id: "vat_proportion", sortId: "price__vat_proportion", label: <LocalizeText tag="vat" /> },
    ];
};

const ProductsView: FC = () => {
    const pagination = usePaginationStore("products", undefined, {
        page_size: 10,
        default_page_size: 10,
    });
    const { communitiesPagination } = useContext(CommunityContext);
    const { openDrawer } = useContext(ModalContext);
    const { hasPermissions } = usePermission();

    useEffect(() => {
        communitiesPagination.getInitial();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="contentWrapper">
            <Banner
                title="products"
                subTitle="bannerProducts"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`}
            />
            <TopBar
                searchField={<SearchField pagination={pagination} tag="searchProducts" />}
                singleItem={
                    hasPermissions(["add_product"]) ? (
                        <VKButton
                            tag="createNew"
                            variant="outlined"
                            size="small"
                            sx={{ marginLeft: "auto" }}
                            onClick={() => openDrawer(CreateProduct, { pagination }, "createProduct")}
                        />
                    ) : null
                }
            />
            <ProductsTable
                pagination={pagination}
                onClick={(product) => openDrawer(ProductInformation, { product, pagination }, "productInfo")}
            />
        </div>
    );
};

export default ProductsView;
