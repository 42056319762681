import React, { FC, useMemo } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { useNavigate } from "react-router-dom";
import { LocalizeText } from "components/localizer";
import { InvoiceOrderStatus } from "components/invoices/invoiceOrderStatus";
import { THeadCell } from "utils/types";
import { TLanguageTag } from "contexts/language";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { IInvoiceOrder, TInvoiceType } from "reducers/invoices";
import { InvoiceStatus } from "components/invoices/invoiceStatus";
import PrettifyPrice from "components/prettifyPrice";
import { Typography } from "@mui/material";
import { formatPossibleNullDate } from "utils/helpers";
import colors from "styles/colors";
import Dropdown, { TDropdownItem } from "components/dropdown";

const customerHeadCells: THeadCell[] = [
    { id: "customer_number", sortId: "customer_number", label: <LocalizeText tag="contractNumber" /> },
    {
        id: "company_name",
        sortId: "company_name",
        label: <LocalizeText tag="name" />,
    },
];
const invoiceOrdersHeadCells: THeadCell[] = [
    { id: "invoiceNumber", label: <LocalizeText tag="invoiceNumber" /> },
    { id: "type", label: <LocalizeText tag="type" /> },
    { id: "created", label: <LocalizeText tag="created" /> },
    { id: "due_date", label: <LocalizeText tag="dueDate" /> },
    {
        id: "amount_to_pay",
        label: (
            <>
                <LocalizeText tag="priceAmount" /> <LocalizeText tag="includingVat" />
            </>
        ),
    },
    { id: "payment_status", label: <LocalizeText tag="status" /> },
    { id: "action", label: "" },
];

interface IProps {
    pagination: IUsePaginationStore<"invoice-orders">;
    includeCustomerData: boolean;
}
export const InvoiceOrdersTable: FC<IProps> = ({ pagination, includeCustomerData }) => {
    const navigate = useNavigate();

    const rows = useMemo<IRow[]>(
        () =>
            pagination.results.map((invoiceOrder) => {
                const { uuid, created, currency_code, invoices } = invoiceOrder;
                const customerData: (string | JSX.Element)[] = [
                    invoiceOrder.customer_number || "-",
                    invoiceOrder.company_name || "-"
                ];

                if (invoices.length > 0) {
                    const invoiceOrderRowData = [
                        <div key={`order_invoice_num_${uuid}`}>
                            {invoices.map(inv => <div key={`indi_invoice_num_${inv.uuid}`}>{inv.invoice_number}</div>)}
                        </div>,
                        <div key={`order_invoice_type_${uuid}`}>
                            {invoices.map(inv => <div key={`indi_invoice_type_${inv.uuid}`}><LocalizeText tag={getLanguageTag(inv.type)} /></div>)}
                        </div>,
                        <div key={`order_invoice_created_${uuid}`}>
                            {invoices.map(inv =>
                                <div key={`indi_invoice_created_${inv.uuid}`}>
                                    {formatPossibleNullDate(inv.created, dateFormats.WEBDATE)}
                                </div>)}
                        </div>,
                        <div key={`order_invoice_overdue_${uuid}`}> {invoices.map(inv => <div key={`indi_invoice_overdue_${inv.uuid}`}><Typography
                            key={`due_date_${inv.uuid}`}
                            color={inv.is_overdue ? colors.errorRed : undefined}
                            variant="inherit">
                            {formatPossibleNullDate(inv.due_date, dateFormats.WEBDATE) || "-"}</Typography></div>)}
                        </div>,
                        <div key={`order_invoice_price_${uuid}`}>
                            {invoices.map(inv =>
                                <div key={`indi_invoice_price_${inv.uuid}`}>
                                    <PrettifyPrice key={`amount_due_${uuid}`} amount={inv.amount_to_pay} currency={currency_code as TLanguageTag} />
                                </div>)
                            }
                        </div>,
                        <div key={`order_invoice_status_${uuid}`}>
                            {invoices.map(inv =>
                                <div key={`indi_invoice_status_${inv.uuid}`}>
                                    <InvoiceStatus invoice={inv} />
                                </div>)}
                        </div>,
                        invoices.length > 1 ? <SelectInvoiceDropdown key={`order_action_${uuid}`} invoiceOrder={invoiceOrder} /> : "",
                    ];
                    return {
                        onClick: invoices.length > 1 ? undefined : (): void => navigate(`/staff-invoices/${invoiceOrder.uuid}/${invoiceOrder.invoices[0].uuid}`),
                        data: includeCustomerData ? customerData.concat(invoiceOrderRowData) : invoiceOrderRowData,
                    };
                } else {
                    const invoiceOrderRowData = [
                        "-",
                        "-",
                        format(parseISO(created), dateFormats.WEBDATE),
                        "-",
                        "-",
                        <InvoiceOrderStatus key={`status_${uuid}`} order={invoiceOrder} shortVersion={true} />,
                        "",
                    ];
                    return {
                        onClick: (): void => navigate(`/staff-invoices/${uuid}`),
                        data: includeCustomerData ? customerData.concat(invoiceOrderRowData) : invoiceOrderRowData,
                    };
                }
            }),
        [pagination.results, navigate, includeCustomerData]
    );

    return (
        <TableComponent
            headCells={includeCustomerData ? customerHeadCells.concat(invoiceOrdersHeadCells) : invoiceOrdersHeadCells}
            isLoading={pagination.query.isFetching}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
        />
    );
};

interface ISelectInvoiceDropdownProps {
    invoiceOrder: IInvoiceOrder;
}

const SelectInvoiceDropdown: FC<ISelectInvoiceDropdownProps> = ({ invoiceOrder }) => {
    const navigate = useNavigate();

    const dropdownItems: TDropdownItem[] = [];
    invoiceOrder.invoices.map((invoice) =>
        dropdownItems.push({
            onClick: () => navigate(`/staff-invoices/${invoiceOrder.uuid}/${invoice.uuid}`),
            label: invoice.invoice_number,
        }));
    return <Dropdown inRow items={dropdownItems} />;
};

function getLanguageTag(currentType: TInvoiceType): TLanguageTag {
    let type: TLanguageTag = "normal";
    switch (currentType) {
        case "PREPAYMENT":
            type = "prepayment";
            break;
        case "CREDIT":
            type = "credit";
            break;
    }
    return type;
}