import React, { FC, ReactNode, Reducer, createContext, useCallback, useContext, useMemo, useReducer } from "react";
import reducer, { IArenas, IAction, IArenaBuilding, initialState, IArena } from "reducers/arenas";
import { NotificationContext } from "contexts/notification";
import { apiGet, apiPatch, apiPatchGet } from "fetchApi";
import { exhaustive } from "exhaustive";

export const ArenasContext = createContext<IArenas>({
    ...initialState,
});

export const ArenasProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { ...notification } = useContext(NotificationContext);
    const [currentState, dispatch] = useReducer<Reducer<IArenas, IAction>>(reducer, initialState);

    const cancelArena = useCallback(
        async (officesubscription_uuid: string, endDate: string): Promise<boolean> => {
            dispatch({ type: "CANCEL_ARENA" });
            const data = {
                end_date: endDate || null,
            };
            const response = await apiPatch<object>(`/offices/${officesubscription_uuid}/cancel/`, data);
            return exhaustive(response, "responseType", {
                Success: () => {
                    dispatch({ type: "CANCEL_ARENA_SUCCESS" });
                    notification.enqueNotification("success_cancelArena");
                    return true;
                },
                Error: (error) => {
                    notification.enqueNotification("error_cancelArena", error);
                    dispatch({ type: "CANCEL_ARENA_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const getBariumRequestMoreArenasURL = useCallback(
        async (membershipId: number): Promise<boolean> => {
            dispatch({ type: "SET_UP_BARIUM" });
            const params = {
                membership_id: membershipId,
            };
            const returnData = await apiGet<object>("/offices/form-url/", { params });
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    if (it.data && "form_url" in it.data && typeof it.data.form_url === "string") {
                        window.open(it.data.form_url);
                        dispatch({ type: "SET_UP_BARIUM_SUCCESS" });
                        return true;
                    }
                    throw new Error("No valid url to barium.");
                },
                Error: (error) => {
                    notification.enqueNotification("error_openBariumForm", error);
                    dispatch({ type: "SET_UP_BARIUM_FAILURE" });
                    return false;
                },
            });
        },
        [notification]
    );

    const getArenaBuilding = useCallback(
        async (arenaBuildingUuid: string): Promise<IArenaBuilding> => {
            dispatch({ type: "FETCH_ARENA_BUILDING" });
            const returnData = await apiGet<IArenaBuilding>(`/memberships/buildings/${arenaBuildingUuid}`);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "FETCH_ARENA_BUILDING_SUCCESS",
                        data: it.data,
                    });
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_fetchBuildings", error);
                    dispatch({ type: "FETCH_AREMA_BUILDING_FAILURE" });
                    return {} as IArenaBuilding;
                },
            });
        },
        [notification]
    );

    const updateArena = useCallback(
        async (uuid: string, data: Record<string, unknown>): Promise<IArena> => {
            dispatch({ type: "UPDATE_ARENA" });
            const returnData = await apiPatchGet<IArena, Record<string, unknown>>(`/offices/${uuid}/`, data);
            return exhaustive(returnData, "responseType", {
                Success: (it) => {
                    dispatch({
                        type: "UPDATE_ARENA_SUCCESS",
                    });
                    notification.enqueNotification("success_updateArena");
                    return it.data;
                },
                Error: (error) => {
                    notification.enqueNotification("error_updateSubscription", error);
                    dispatch({ type: "UPDATE_ARENA_FAILURE" });
                    return {} as IArena;
                },
            });
        },
        [notification]
    );

    const value = useMemo(() => {
        return {
            ...currentState,
            cancelArena,
            getBariumRequestMoreArenasURL,
            getArenaBuilding,
            updateArena,
        };
    }, [currentState, cancelArena, getBariumRequestMoreArenasURL, getArenaBuilding, updateArena]);

    return <ArenasContext.Provider value={value}>{children}</ArenasContext.Provider>;
};

export default ArenasContext;
