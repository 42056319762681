import React, { FC, useContext, useState } from "react";
import { LocalizeText } from "components/localizer";
import ConfirmDialog from "components/confirmDialog";
import { CancelArenaDatePicker } from "./cancelArenaDatePicker";
import { IArena } from "reducers/arenas";
import { List, ListItemText } from "@mui/material";
import { IMembership } from "reducers/memberships";
import { dateFormats } from "utils/formats";
import { format } from "date-fns";
import { ProfileContext } from "contexts/profile";

interface IProps {
    membership?: IMembership;
    arena: IArena;
    dateEnd: string;
    onHandleSubmitEndDate: (newDate: string) => void;
}

export const CancelArena: FC<IProps> = ({ membership, arena, dateEnd, onHandleSubmitEndDate }) => {
    const [endDate, setEndDate] = useState<string>(dateEnd);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const { user } = useContext(ProfileContext);

    const desc = (
        <List>
            <ListItemText
                primary={
                    <LocalizeText
                        tag="removeArenaMailPreview"
                        replace={{
                            org: `${membership?.organization.company_name || membership?.organization.name}`,
                            agreementNr: `${membership?.agreement_nr}`,
                            arenaOfficeName: arena.office_name,
                            arena: arena.community_title,
                            city: arena.community_city,
                            endDate: endDate ? format(endDate, dateFormats.WEBDATE) : "",
                            cancelCity: arena.community_city,
                            cancelDate: format(new Date(), dateFormats.WEBDATE),
                            contactPerson: `${user?.first_name} ${user?.last_name} (${user?.email})`,
                        }}
                    />
                }
            ></ListItemText>
        </List>
    );

    const onSubmitEndDate = async (date: string): Promise<void> => {
        setEndDate(date);
        if (date) {
            setShowConfirm(true);
        } else {
            onHandleSubmitEndDate(date);
        }
    };

    return (
        <>
            <CancelArenaDatePicker
                onSubmit={(date: string) => onSubmitEndDate(date)}
                dateEnd={endDate}
                earliestCancellationDate={arena.earliest_cancellation_date}
            />

            <ConfirmDialog
                open={showConfirm}
                title={<LocalizeText tag="editFinish" />}
                description={desc}
                onReject={() => setShowConfirm(false)}
                onAccept={() => onHandleSubmitEndDate(endDate)}
            />
        </>
    );
};
