import React, { ReactNode, useContext, useState } from "react";
import VKButton from "components/vkButton";
import ArrowIcon from "@mui/icons-material/NavigateNext";
import { IArena } from "reducers/arenas";
import { LocalizeText } from "components/localizer";
import { ListTextField } from "components/drawers/drawerComponents";
import { formatBindingPeriod, getExpireDate } from "utils/ecommerseTypes";
import { MembershipsContext } from "contexts/memberships";
import { List, ListItem, Divider } from "@mui/material";
import PrettifyPrice from "components/prettifyPrice";
import { ListItemDiscounts } from "../listItemDiscounts";
import { ListItemWithButton } from "../subscription/listItemWithButton";
import { EditArenaStartDate } from "./editArenaStartDate";
import { ModalContext } from "contexts/modals";
import { usePermission } from "utils/hooks/usePermission";
import { CancelArena } from "./cancelArena";
import { parseISO, format } from "date-fns";
import { dateIsFutureOrToday } from "utils/helpers";
import { dateFormats } from "utils/formats";

interface IProps {
    arena: IArena;
    onSubmitUpdate: (params: Partial<IArena>) => void;
    onAcceptRemoveArena: (newDate: string) => void;
}
const ArenaInformation: React.FC<IProps> = ({ arena, onSubmitUpdate, onAcceptRemoveArena }) => {
    const { openDrawer } = useContext(ModalContext);
    const { membership } = useContext(MembershipsContext);
    const [startDate, setStartDate] = useState(arena.start_date);
    const [endDate, setEndDate] = useState(arena.end_date);
    const { hasPermission } = usePermission();

    const onHandleSubmitEndDate = (newDate: string): void => {
        setEndDate(newDate);
        onAcceptRemoveArena(newDate);
    };

    const onHandleSubmit = (newDate: string): void => {
        setStartDate(newDate);
        onSubmitUpdate({ start_date: newDate });
    };

    const getRentalPeriod = (): ReactNode => {
        if (!endDate) {
            return <LocalizeText tag="untilFurtherNotice" />;
        }
        return (
            <span>
                <LocalizeText tag="until" />
                {". "} {getExpireDate(format(endDate, dateFormats.WEBDATE))}
            </span>
        );
    };

    return (
        <>
            <List data-testid="arenainformation-root">
                <ListTextField
                    hasData
                    label={<LocalizeText tag="memberships" />}
                    data={
                        <span>
                            <LocalizeText tag="customerNumber" /> {membership?.agreement_nr}
                        </span>
                    }
                />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="city" />} data={arena.community_city} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="arena" />} data={arena.community_title} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="arenaOffice" />} data={arena.office_name} />
                <Divider />
                <ListTextField hasData label={<LocalizeText tag="officeSpaces" />} data={arena.total_office_spaces} />
                <Divider />
                <ListItemWithButton
                    primary={<LocalizeText tag="moveInDate" />}
                    secondary={startDate}
                    button={{
                        onClick: () =>
                            openDrawer(
                                EditArenaStartDate,
                                { dateStart: startDate, dateEnd: arena.end_date, onHandleSubmit },
                                "editOccupancyStartDate"
                            ),
                        tag: "edit",
                        show:
                            hasPermission("can_change_officesubscription_start_date") &&
                            !arena.final_payment_made &&
                            arena.not_invoiced,
                    }}
                />
                <ListTextField
                    hasData
                    label={<LocalizeText tag="bindingPeriod" />}
                    data={formatBindingPeriod(arena.binding_period_until)}
                />
                <Divider />
                <ListItemWithButton
                    primary={<LocalizeText tag="rentalPeriod" />}
                    secondary={getRentalPeriod()}
                    button={{
                        onClick: () =>
                            openDrawer(
                                CancelArena,
                                {
                                    membership: membership,
                                    arena: arena,
                                    dateEnd: endDate,
                                    onHandleSubmitEndDate,
                                },
                                "terminatingRentalContract"
                            ),
                        tag: "edit",
                        show:
                            !arena.end_date ||
                            (hasPermission("can_cancel_officesubscription") &&
                                dateIsFutureOrToday(parseISO(arena.end_date ?? "")) &&
                                !arena.final_payment_made),
                    }}
                />
                <ListTextField
                    hasData
                    label={<LocalizeText tag="rentPerMonth" />}
                    data={
                        <>
                            <PrettifyPrice amount={arena.base_cost_excl_vat} decimals={0} />{" "}
                            <LocalizeText tag="excludingVat" />
                        </>
                    }
                />
                <Divider />
                <ListItemDiscounts
                    type="offices"
                    type_id={arena.id}
                    max_amount_excl_vat={arena.base_cost_excl_vat}
                    subscription={arena}
                />
            </List>
            <List>
                <ListItem>
                    <VKButton
                        tag="openContract"
                        template="primary"
                        data-testid="arenainformation-addButton"
                        disabled={!arena.agreement_document_file}
                        onClick={() => window.open(arena.agreement_document_file)}
                        rightAddon={<ArrowIcon />}
                    />
                </ListItem>
            </List>
        </>
    );
};

export default ArenaInformation;
